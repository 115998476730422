import React from "react"
import { Router } from "@reach/router"
import Helmet from "react-helmet"
import Home from "../pages/home"
import EnquiryForm from "../pages/enquire"
import Success from "../pages/success"

function AppRouter({ headerImage, boatImage, boatInteriorImage }) {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="oH67_b8vpzEV1Des9V3JEa_nd1aRWJC5Kd-UeY78fzg"
        />
        <script
          async
          src="https://www.tripadvisor.com/WidgetEmbed-cdsratingsonlynarrow?amp;locationId=17821239&border=true&uniq=350&lang=en_US&display_version=2"
          data-loadtrk
          onload="this.loadtrk=true"
        ></script>
      </Helmet>
      <Router>
        <Home
          path="/"
          headerImage={headerImage}
          boatImage={boatImage}
          boatInteriorImage={boatInteriorImage}
        />
        <EnquiryForm path="enquire" />
        <Success path="success" />
      </Router>
    </>
  )
}

export default AppRouter
