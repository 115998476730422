import React from "react"
import styled from "styled-components"
import { Link } from "@reach/router"
import Layout from "../components/constructs/layout"
import P from "../components/primitives/p"
import theme from "../config/theme"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: ${theme.colors.blue2};
`

const Success = () => (
  <Layout
    title="SeaCab: Thank you for succesfully enquiring with our water-taxi"
    description="Water-taxi enquiry has been received. We will respond shortly and help to get you form Skiathos to Skopelos sooner. Skip the ferry schedule and get there now."
  >
    <Container>
      <P>Thank you for your enquiry with SeaCab!</P>
      <P>We will get back to you shortly.</P>
      <Link to="/">
        <P>Back to Home</P>
      </Link>
    </Container>
  </Layout>
)

export default Success
