import React from "react"
import { ThemeProvider } from "styled-components"
import { graphql } from "gatsby"
import theme from "../config/theme"
import App from "../pages/app"

const IndexPage = ({ data }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <App
          headerImage={data.headerImage}
          boatImage={data.boatImage}
          boatInteriorImage={data.boatInteriorImage}
        />
      </ThemeProvider>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "header.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    boatImage: file(relativePath: { eq: "boat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    boatInteriorImage: file(relativePath: { eq: "boat_interior.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
